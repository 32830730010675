@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  /* outline: 1px solid rgba(4, 0, 221, 0.568); */
  /* outline: 1px solid rgba(255, 7, 7, 0.568); */
}

body {
  font-family: "DM Sans", sans-serif;
}

a:hover {
  color: #ffa6a8 !important;
}

.border-gradient {
  border: 2px solid transparent;
  border-image-slice: 1;
  border-width: 2px;
  background: linear-gradient(to right, #8830c0 0%, #ea6669 100%) border-box;
  background: linear-gradient(white, white) padding-box,
    linear-gradient(to right, #8830c0, #ea6669) border-box;
  border-radius: 10px;
}

.gradient-title {
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  /* background: linear-gradient(160deg, #8830c0 0%, #ea6669 100%); */
  background: linear-gradient(to right, #8830c0 0%, #ea6669 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: fit-content;
}

.gradient-title.text-xs {
  font-size: 12px;
}

.gradient-title.text-sm {
  font-size: 14px;
}

.gradient-title.text-base {
  font-size: 18px;
}
.gradient-title.text-lg {
  font-size: 20px;
}
.gradient-title.text-2lg {
  font-size: 22px;
}
.gradient-title.text-3lg {
  font-size: 24px;
}

.text-shadow {
  text-shadow: 0px 0px 4px rgba(234, 102, 105, 0.5);
}

.gradient-title.font-normal {
  font-weight: 400;
}

.billing-title {
  font-size: 55px;
}

.table-row-shadow {
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
}

/* loader  */
.lds-ellipsis {
  position: relative;
  height: 30px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.lds-ellipsis div {
  position: absolute;
  transform: translateY(-50%);
  width: 13px;
  height: 13px;
  border-radius: 50%;
  /* background: #fff; */
  background: linear-gradient(to right, #8830c0 0%, #ea6669 100%);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}

.dot-span {
  background: linear-gradient(to right, #8830c0 0%, #ea6669 100%);
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 100px;
}

.lsf-sidebar-tabs__content {
  max-height: 80vh !important;
}

.lsf-wrapper {
  height: 100vh;
  background-color: #f2f2f2;
}

@media (min-wdth: 1280px) {
  .gradient-title {
    font-size: 25px;
  }
}
